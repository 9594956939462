window._ = require('lodash');
require('bootstrap');
window.$ = window.jQuery = require('jquery');
import 'alpinejs'
import 'jquery-match-height';
import '@babel/polyfill';

let momentJs = require('moment');
let Pikaday = require('pikaday/pikaday');
window.Pikaday = Pikaday;
window.moment = momentJs;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

document.addEventListener('livewire:load', function () {
    window.livewire.on('urlChange', (url) => {
        history.pushState(null, null, url);
    });
});

// document.addEventListener("turbolinks:load", function() {
    // Ran on every page change


// });

(function ($) {
    // Ran only on first load
    $('.dropdown-toggle').dropdown();

    $('#template.page.events .event-holder .event').matchHeight();
    $('#template.home .top .blocks .block h2.matchHeight').matchHeight();
    $('#template.home .top .blocks .block p.matchHeight').matchHeight();
    $('#template.home .upcoming .events .event').matchHeight();
    $('#template.home .upcoming .events .event .title').matchHeight();

    $('.book-position').click(function(e){
        $('#tabs li a[aria-selected="true"]').attr('aria-selected', 'false').removeClass('active');
        $('#myTabContent .tab-pane').removeClass('active').removeClass('show');
        $('#dates-tab').addClass('active');
        $('#dates').tab('show');
        $('html,body').animate({scrollTop: $('#dates').offset().top-200}, 500);

        e.preventDefault();
    });

    $('.navbar-toggler').click(function () {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
        } else {
            $(this).addClass('active');
        }
    });

    $('.cookie-policy button').click(function(){
        var obj = $('.cookie-policy');
        obj.animate({marginTop: obj.outerHeight() *-1}, 150, 'swing', function() {
            $(this).remove();
        });
    });
})(jQuery);
